import React, { useState } from "react";
import HeroSection from "../components/heroSection";
import InfoSection from "../components/infoSection";
import { Navbar } from "./../components/navbar";
import { Sidebar } from "./../components/sidebar";

import Image1 from "../images/design-notes.svg";
import Image2 from "../images/space.svg";
import InfoSectionLight from "../components/infoSectionLight";
import Services from "../components/services";
import Footer from "../components/footer";

export const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <InfoSection
        image={Image1}
        id="about"
        subtitle="An innovative document management system"
        title="Document Analysis"
        text="Big Data management and analysis technologies are exponentially spreading.
        Inputless Analytics uses artificial intelligence and an efficient graph-based visualization system to take document dematerialization to a higher level."
        btnText="Start today"
      />
      <InfoSectionLight
        image={Image2}
        id="discover"
        subtitle="Built for automation"
        title="Automate all boring stuff"
        text="Many analytics platforms are just too complex and time consuming to learn from the user's perpective.
        Inputless Analytics does not require any manual input, just upload any type of text files to be able to explore large document archives in a few moments."
        btnText="Explore"
      />
      <Services />
      <InfoSectionLight
        image={Image1}
        id="#"
        subtitle="Graph Exploration"
        title="Graph Knowledge"
        text="The use of graphs for the representation of knowledge is one of the greatest innovations of the century in the field of data science. Various companies invest in these technologies to improve the operations of organization, research and data analysis."
        btnText="Read more"
      />

<InfoSectionLight
        image={Image1}
        id="#"
        subtitle="Build with security in mind"
        title="Security"
        text="Connection to access to your infrastructure should be ENCRYPTED through a VPN connection, so you can access in a secure way.
        Raw uploaded documents can be removed from the system at the end of the extraction process.
        Extracted informations/entities are stored in a secure and encrypted database."
        btnText="Read more"
      />
      <Footer />
    </>
  );
};
